import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/episode-layout.js";
import { Link } from 'gatsby';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "ep2---eva-dee-from-teaching-mandarin-to-becoming-a-web-developer"
    }}>{`Ep2 - Eva Dee: From teaching Mandarin to becoming a web developer`}</h1>
    <div className="soundcloud-iframe">
    <iframe className="mb-0" width="100%" height="20" scrolling="no" frameBorder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/868848340&color=%23fc4056&inverse=true&auto_play=false&show_user=true"></iframe>
    </div>
    <Link className="link-button mr-5" to="/transcripts/ep2-eva-dee/" mdxType="Link">Transcript</Link>
    <a className="link-button" href="https://www.youtube.com/watch?v=fg6YxXc2Mrg">Watch this talk</a>
    <h2 {...{
      "id": "description"
    }}>{`Description`}</h2>
    <p>{`In this episode, we talk with Eva Dee a Web Developer at Vibbio. Eva shares her journey from being a Mandarin teacher to becoming a web developer. We will share with you tips on how to get a job, how to stand out from the crowd and get that position that you love.`}</p>
    <p>{`Eva's first contact with the developer world started with her work on a startup.  Her internship at Mozilla helped a lot when applying to developer jobs, but she also shares her experience and how important the community involvement was for her.`}</p>
    <p>{`We talk a lot about soft skills and how important communication is in the development world, especially if you are working remotely.  We also talk about being a Junior developer and Eva shares a good piece of advice, that is okay to make mistakes and that you should enjoy the ride.`}</p>
    <p>{`According to Eva, one of the best assets that you can have is your network and that as a junior developer you should put the time into talking with other devs and attend meetups.`}</p>
    <h2 {...{
      "id": "guest-information"
    }}>{`Guest Information`}</h2>
    <div className="episode-card">
    <img src="https://landingintech.com/eva-dee.png" alt="Eva Dee" className="guest-avatar" />
    <div className="mx-8 pt-5">
        <p className="episode-title text-xl">Eva Dee</p>
        <p className=''>Web Developer</p>
        <p>
            <a href="https://includejs.dev/"> Website</a> | <a href="https://www.twitch.tv/edieblu"> Twitch</a> | <a href="https://twitter.com/GirlsCodeMK"> Twitter</a>
        </p>
    </div>
    </div>
    <h2 {...{
      "id": "resources-and-links"
    }}>{`Resources and Links`}</h2>
    <p>{`Through the talk we have mentioned a lot of interesting projects, books, videos and other resources, you can find all about them in this section.`}</p>
    <h3 {...{
      "id": "open-source-projects"
    }}>{`Open Source Projects`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tailwindcss.com/"
        }}>{`TailwindCSS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://svelte.dev/"
        }}>{`Svelte`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.11ty.dev/"
        }}>{`Eleventy`}</a></li>
    </ul>
    <h3 {...{
      "id": "online-courses"
    }}>{`Online Courses`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://egghead.io/"
        }}>{`Egghead`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://frontendmasters.com/"
        }}>{`Frontend Masters`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.edx.org/course/cs50s-introduction-to-computer-science"
        }}>{`CS50`}</a></li>
    </ul>
    <h3 {...{
      "id": "other-resources"
    }}>{`Other Resources`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://getmakerlog.com/log"
        }}>{`GetMakerlog - Maker community`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.partycorgi.com/"
        }}>{`Party Corgi Community`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://includejs.dev/"
        }}>{`IncludeJS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://howtoegghead.com/advocate/"
        }}>{`Egghead Learners Advocate Program`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      